<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Produits</h4>
        <Dialog
          v-model:visible="dialogOfimport"
          :style="{ width: '450px' }"
          header="IMPORTATION"
          :modal="true"
          class="p-fluid"
        >
        <!-- <input type="file" @change="uploadFile" ref="file"> -->
        <!-- <button @click="submitFile">Import!</button> -->
        <div v-if="!loader">
          <FileUpload mode="basic" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ref="file" @change="uploadFile" :fileLimit="1" style="width: 100%" label="Importer " chooselabel="Importer " class="mr-2 inline-block" />
            <small style="color:red" v-if="ImagesError"> {{ImagesError}}</small>
          <Button label="télécharger le modèle" icon="pi pi-upload" class="p-button-help" @click="exportCSVexemple()" style="width: 100% ;margin-top:8px"  />
        </div>
          <div v-else>
            <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
          </div>

          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfimport = false"
            />
            <Button
              label="ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="importer"
            />
          </template>
        </Dialog>
        <Toolbar class="mb-4" v-if="Permissions.add">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter un nouveau Produit"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="showaddPoduit()"
              />
            </div>
          </template>
          <template v-slot:end>
						<!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Importer " chooselabel="Importer " class="mr-2 inline-block" /> -->
            <Button  label="Importer " icon="pi pi-plus" chooselabel="Importer " @click="showImport()" class="mr-2 inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV()"  />
					</template>
        </Toolbar>
        <DataTable
          :value="produits"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          :loading="loading1"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'nomProduit',
            'prixAchat',
            'prixVente',
            'stock',
            'nomCategorie',
            'numSerie',
            'seul',
            'descriptionProduit',
          ]"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty> Aucun produit trouvé. </template>
          <template #loading>
            Chargement des données produits. Veuillez patienter.
          </template>
          <Column field="nomProduit" header="Libellé" :sortable="true">
            <template #body="{ data }">
              {{ data.nomProduit }}
            </template>
          </Column>
          <Column
            field="descriptionProduit"
            header="Description"
            :sortable="true"
          >
            <template #body="{ data }">
              {{ data.descriptionProduit }}
            </template>
          </Column>
          <Column field="numSerie" header="série" :sortable="true">
            <template #body="{ data }">
              {{ data.numSerie }}
            </template>
          </Column>
          
          <Column field="prixAchat" header="Prix d'achat" :sortable="true">
            <template #body="{ data }">
              {{ data.prixAchat }}
            </template>
          </Column>
          <Column field="prixVente" header="Prix de vente" :sortable="true">
            <template #body="{ data }">
              {{ data.prixVente }}
            </template>
          </Column>
          <Column field="stock" header="Stock" :sortable="true">
            <template #body="{ data }">
              {{ data.stock }}
            </template>
          </Column>
          <Column field="seul" header="Seuil" :sortable="true">
            <template #body="{ data }">
              {{ data.seul }}
            </template>
          </Column>

          <Column field="nomCategorie" header="Catégorie" :sortable="true">
            <template #body="{ data }">
              {{ data.nomCategorie }}
            </template>
          </Column>
          <Column header="Image">
            <template #body="slotProps">
              <img
                :src="
                  url +
                  slotProps.data.imageProduit
                "
                class="shadow-2"
                width="100"
              />
            </template>
          </Column>
          <Column header="Actions">
            <template #body="data">
              <span class="p-buttonset">
                <!-- <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                /> -->
                <Button
                  v-if="Permissions.update"
                  style="margin-right: 6px"
                  icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="updateClient(data.data.id)"
                />
                <Button
                  v-if="Permissions.delete"
                  icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger"
                  @click="deleteclient(data.data.id)"
                />
              </span>
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-model:visible="dialogOfAdd"
          :style="{ width: '900px' }"
          header="Ajouter un produit"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="firstname2">Libellé </label>

                <InputText
                  v-model="produitToAdd.nomProduit"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['nomProduit'] }"
                />
                <small class="p-error" v-if="errorsAdd['nomProduit']">
                  {{ errorsAdd["nomProduit"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Numéro de série</label>
                <InputText
                  v-model="produitToAdd.numSerie"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['numSerie'] }"
                />
                <small class="p-error" v-if="errorsAdd['numSerie']">
                  {{ errorsAdd["numSerie"] }}
                </small>
              </div>
              <div class="field col-12 md:col-12">
                <label for="firstname2">Description </label>
                <Textarea
                  v-model="produitToAdd.descriptionProduit"
                  :autoResize="true"
                  rows="2"
                  cols="30"
                  :class="{ 'is-invalid': errorsAdd['descriptionProduit'] }"
                />
                <small class="p-error" v-if="errorsAdd['descriptionProduit']">
                  {{ errorsAdd["descriptionProduit"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="lastname2">Catégorie </label>
                <Dropdown
                  :class="{ 'is-invalid': errorsAdd['nomCategorie'] }"
                  v-model="categorie"
                  :options="categories"
                  optionLabel="nomCategorie"
                  placeholder="sélectionner une Catégorie"
                ></Dropdown>
                <small class="p-error" v-if="errorsAdd['nomCategorie']">
                  {{ errorsAdd["nomCategorie"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="lastname2">Vente par </label>
                <Dropdown
                  v-model="produitToAdd.unite"
                  :options="cats"
                  placeholder="Sélectionner"
                ></Dropdown>
              </div>
              <div class="field col-12 md:col-4">
                <label for="lastname2">Fournisseur</label>
                <MultiSelect
                  :class="{ 'is-invalid': errorsAdd['fournisseur_id'] }"
                  v-model="multiselectValueAdd"
                  :options="fournisseurs"
                  optionLabel="rsocial"
                  placeholder="Sélectionner les Fournisseurs"
                  :filter="true"
                >
                  <template #value="slotProps">
                    <div
                      class="
                        inline-flex
                        align-items-center
                        py-1
                        px-2
                        bg-primary
                        text-primary
                        border-round
                        mr-2
                      "
                      v-for="option of slotProps.value"
                      :key="option.id"
                    >
                      <span style="width: 18px; height: 12px" />
                      <div>{{ option.rsocial }}</div>
                    </div>
                    <template
                      v-if="!slotProps.value || slotProps.value.length === 0"
                    >
                      <div class="p-1">Sélectionner les Fournisseurs</div>
                    </template>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <span style="width: 18px; height: 12px" />
                      <div>
                        {{ slotProps.option.rsocial }}
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <small class="p-error" v-if="errorsAdd['fournisseur_id']">
                  {{ errorsAdd["fournisseur_id"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="city">Prix d'achat </label>
                <InputNumber
                  :class="{ 'is-invalid': errorsAdd['prixAchat'] }"
                  v-model="produitToAdd.prixAchat"
                  mode="currency"
                  currency="MAD"
                />
                <small class="p-error" v-if="errorsAdd['prixAchat']">
                  {{ errorsAdd["prixAchat"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="prixVente">Prix de vente </label>
                <InputNumber
                  :class="{ 'is-invalid': errorsAdd['prixVente'] }"
                  v-model="produitToAdd.prixVente"
                  mode="currency"
                  currency="MAD"
                />
                <small class="p-error" v-if="errorsAdd['prixVente']">
                  {{ errorsAdd["prixVente"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Stock </label>
                <InputNumber
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-danger"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  v-model="produitToAdd.stock"
                  :min="0"
                  :class="{ 'is-invalid': errorsAdd['stock'] }"
                />
                <small class="p-error" v-if="errorsAdd['stock']">
                  {{ errorsAdd["stock"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Seuil </label>
                <InputNumber
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-danger"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  v-model="produitToAdd.seul"
                  :min="0"
                  :class="{ 'is-invalid': errorsAdd['seul'] }"
                />
                <small class="p-error" v-if="errorsAdd['seul']">
                  {{ errorsAdd["seul"] }}
                </small>
              </div>
              

              <div class="field col-12 md:col-12">
                <label for="lastname2">Image</label>
                <br />
                <div class="p-fluid formgrid grid">
                  <div class="col-12 md:col-4">
                    <Image
                      v-if="previewImageup"
                      :src="'https://evebiomonde.com' + previewImageup"
                      alt="Image"
                      width="100"
                      height="100"
                      preview
                    />
                    <Button
                      v-if="previewImageup"
                      class="p-button-danger"
                      icon="pi pi-trash"
                      @click="previewImageup = null"
                    />
                    <Image
                      v-if="previewImage"
                      :src="previewImage"
                      alt="Image"
                      width="100"
                      height="100"
                      preview
                      disabled
                    />
                    <Button
                      v-if="previewImage"
                      class="p-button-danger"
                      icon="pi pi-trash"
                      @click="removeImage(previewImage)"
                    />
                  </div>
                </div>
                <Button
                  :disabled="previewImageup"
                  @click="selectImage"
                  icon="pi pi-plus"
                  class="p-button-success"
                  iconPos="right"
                  label="Insérez une image"
                ></Button>
                <input
                  style="display: none"
                  ref="fileInput"
                  type="file"
                  @input="pickFile"
                />
                <small class="p-error" v-if="errorsAdd['icone']">
                  {{ errorsAdd["icone"] }}
                </small>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfAdd = false"
            />
            <Button
              label="ajouter"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="dialogOfEdit"
          :style="{ width: '900px' }"
          header="Modifier"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="firstname2">Libellé </label>

                <InputText
                  v-model="produitToAdd.nomProduit"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['nomProduit'] }"
                />
                <small class="p-error" v-if="errorsAdd['nomProduit']">
                  {{ errorsAdd["nomProduit"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Numéro de série</label>
                <InputText
                  v-model="produitToAdd.numSerie"
                  type="text"
                  :class="{ 'is-invalid': errorsAdd['numSerie'] }"
                />
                <small class="p-error" v-if="errorsAdd['numSerie']">
                  {{ errorsAdd["numSerie"] }}
                </small>
              </div>
              <div class="field col-12 md:col-12">
                <label for="firstname2">Description </label>
                <Textarea
                  v-model="produitToAdd.descriptionProduit"
                  :autoResize="true"
                  rows="2"
                  cols="30"
                  :class="{ 'is-invalid': errorsAdd['descriptionProduit'] }"
                />
                <small class="p-error" v-if="errorsAdd['descriptionProduit']">
                  {{ errorsAdd["descriptionProduit"] }}
                </small>
              </div>
              
              <div class="field col-12 md:col-4">
                <label for="lastname2">Catégorie </label>
                <Dropdown
                  :class="{ 'is-invalid': errorsAdd['nomCategorie'] }"
                  v-model="categorie"
                  :options="categories"
                  optionLabel="nomCategorie"
                  placeholder="sélectionner une Catégorie"
                ></Dropdown>
                <small class="p-error" v-if="errorsAdd['nomCategorie']">
                  {{ errorsAdd["nomCategorie"] }}
                </small>
              </div>
              <div class="field col-12 md:col-4">
                <label for="lastname2">Vente par </label>
                <Dropdown
                  v-model="produitToAdd.unite"
                  :options="cats"
                  placeholder="sélectionner"
                ></Dropdown>
              </div>
              <div class="field col-12 md:col-4">
                <label for="lastname2">Fournisseur</label>
                <MultiSelect
                  :class="{ 'is-invalid': errorsAdd['fournisseur_id'] }"
                  v-model="multiselectValueAdd"
                  :options="fournisseurs"
                  optionLabel="rsocial"
                  placeholder="Sélectionner les Fournisseurs"
                  :filter="true"
                >
                  <template #value="slotProps">
                    <div
                      class="
                        inline-flex
                        align-items-center
                        py-1
                        px-2
                        bg-primary
                        text-primary
                        border-round
                        mr-2
                      "
                      v-for="option of slotProps.value"
                      :key="option.id"
                    >
                      <span style="width: 18px; height: 12px" />
                      <div>{{ option.rsocial }}</div>
                    </div>
                    <template
                      v-if="!slotProps.value || slotProps.value.length === 0"
                    >
                      <div class="p-1">Sélectionner les Fournisseurs</div>
                    </template>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <span style="width: 18px; height: 12px" />
                      <div>
                        {{ slotProps.option.rsocial }}
                      </div>
                    </div>
                  </template>
                </MultiSelect>
                <small class="p-error" v-if="errorsAdd['fournisseur_id']">
                  {{ errorsAdd["fournisseur_id"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="city">Prix d'achat </label>

                <InputNumber
                  :class="{ 'is-invalid': errorsAdd['prixAchat'] }"
                  v-model="produitToAdd.prixAchat"
                  mode="currency"
                  currency="MAD"
                />
                <small class="p-error" v-if="errorsAdd['prixAchat']">
                  {{ errorsAdd["prixAchat"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="prixVente">Prix de vente </label>
                <InputNumber
                  :class="{ 'is-invalid': errorsAdd['prixVente'] }"
                  v-model="produitToAdd.prixVente"
                  mode="currency"
                  currency="MAD"
                />
                <small class="p-error" v-if="errorsAdd['prixVente']">
                  {{ errorsAdd["prixVente"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Stock </label>
                <InputNumber
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-danger"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  v-model="produitToAdd.stock"
                  :min="0"
                  :class="{ 'is-invalid': errorsAdd['stock'] }"
                />
                <small class="p-error" v-if="errorsAdd['stock']">
                  {{ errorsAdd["stock"] }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="lastname2">Seuil </label>
                <InputNumber
                  showButtons
                  buttonLayout="horizontal"
                  decrementButtonClass="p-button-danger"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  v-model="produitToAdd.seul"
                  :min="0"
                  :class="{ 'is-invalid': errorsAdd['seul'] }"
                />
                <small class="p-error" v-if="errorsAdd['seul']">
                  {{ errorsAdd["seul"] }}
                </small>
              </div>
              
              

              <div class="field col-12 md:col-12">
                <label for="lastname2">Image</label>
                <br />
                <div class="p-fluid formgrid grid">
                  <div class="col-12 md:col-4">
                    <Image
                      v-if="previewImageup"
                      :src="
                        url + previewImageup
                      "
                      alt="Image"
                      width="100"
                      height="100"
                      preview
                    />
                    <Button
                      v-if="previewImageup"
                      class="p-button-danger"
                      icon="pi pi-trash"
                      @click="previewImageup = null"
                    />
                    <Image
                      v-if="previewImage"
                      :src="previewImage"
                      alt="Image"
                      width="100"
                      height="100"
                      preview
                      disabled
                    />
                    <Button
                      v-if="previewImage"
                      class="p-button-danger"
                      icon="pi pi-trash"
                      @click="removeImage(previewImage)"
                    />
                  </div>
                </div>
                <Button
                  :disabled="previewImageup"
                  @click="selectImage"
                  icon="pi pi-plus"
                  class="p-button-success"
                  iconPos="right"
                  label="Insérez une image"
                ></Button>
                <input
                  style="display: none"
                  ref="fileInput"
                  type="file"
                  @input="pickFile"
                />
                <small class="p-error" v-if="errorsAdd['icone']">
                  {{ errorsAdd["icone"] }}
                </small>
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Annuler"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogOfEdit = false"
            />
            <Button
              label="Modifier"
              icon="pi pi-check"
              class="p-button-text"
              @click="checkFormAdd"
            />
          </template>
        </Dialog>
        <ConfirmDialog></ConfirmDialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      url:null,
      Permissions: { update: false, delete: false, add: false, show: false },
      produits: [],
      filters1: null,
      loading1: true,

      dialogOfShow: false,
      clientToShow: {},

      dialogOfEdit: false,
      updatesubmitted: false,

      dialogOfAdd: false,
      previewImage: null,
      previewImageup: null,
      produitToAdd: {
        fournisseur_id: [],
        id: null,
        nomProduit: null,
       
        prixAchat: null,
        prixVente: null,
        stock: null,
        seul: null,
        nomCategorie: null,
      
        numSerie: null,
        etat: 1,
        imageProduit: null,
        unite: "unité",
      },
      image: null,
      categories: [],
      categorie: null,
      cats: ["unité", "kg"],

      errorsAdd: [],
      fournisseurs: [],
      multiselectValueAdd: [],
      feature_id: null,
      ImagesError:null,
      dialogOfimport: false,
      Images:null,
      loader: false,
    };
  },

  mounted() {
    this.url = this.$Gurl;
    this.Permissions.update =
      localStorage.permissionNames.includes("Modifier produit");
    this.Permissions.delete =
      localStorage.permissionNames.includes("Supprimer produit");
    this.Permissions.add =
      localStorage.permissionNames.includes("Ajouter produit");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste produit");
    if (this.Permissions.show) {
      this.showAllProduit();
    } else {
      this.$router.push("/");
    }
  },

  created() {
    // if (localStorage.token == "" || localStorage.token == null) {
    //   this.$router.push("/login");
    // }
    this.initFilters1();
  },
  methods: {
    uploadFile() {
        this.Images = this.$refs.file.files[0];
        console.log(this.Images);
      },
    importer(){
      this.ImagesError=null;
      if (this.Images==null) {
        this.ImagesError='Merci de sélectionner un fichier excel';
      }else{
        console.log("excel",this.Images);
        this.loader =true;
      const formData = new FormData();
      formData.append("fichierExcel", this.Images);
      axios
        .post("importProduit", formData)
        .then((response) => {
          console.log(response);
          this.loader =false;
          this.dialogOfimport = false;
          this.Images=null;
          this.showAllProduit();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
      }
     
    },
    showImport(){
        this.dialogOfimport=true;
    },
    exportCSVexemple(){
			window.open('https://gestion.spstechnologie.com/fichierExcel/exempleProduit.xlsx', '_blank');
    },
    exportCSV() {
			window.open('https://gestion.spstechnologie.com/api/exportProduit', '_blank');
		},
    deleteclient(id) {
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("supprimerProduit/" + id)
            .then(() => {
              var index = this.produits
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.produits.splice(index, 1);
              console.log(this.produits);
              this.$swal({
                icon: "success",
                title: "Supprimé",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    updateclient() {
      const formData = new FormData();
      formData.append("nomProduit", this.produitToAdd.nomProduit);
     
      formData.append("prixAchat", this.produitToAdd.prixAchat);
      formData.append("prixVente", this.produitToAdd.prixVente);
      formData.append("stock", this.produitToAdd.stock);
      formData.append("nomCategorie", this.produitToAdd.nomCategorie);
      formData.append("seul", this.produitToAdd.seul);
      formData.append("unite", this.produitToAdd.unite);
      formData.append(
        "descriptionProduit",
        this.produitToAdd.descriptionProduit
      );
      
      formData.append("numSerie", this.produitToAdd.numSerie);
      formData.append("etat", this.produitToAdd.etat);

      formData.append("categorie_id", this.categorie.id);

      formData.append(
        "fournisseur_id",
        JSON.stringify(this.produitToAdd.fournisseur_id)
      );
      if (this.image == null && this.previewImageup == null) {
        formData.append("imageProduit", "deleted");
      }
      if (this.image == null && this.previewImageup != null) {
        formData.append("imageProduit", "");
      }
      if (this.image != null && this.previewImageup == null) {
        formData.append("imageProduit", this.image);
      }

      axios
        .post("modifierProduit/" + this.produitToAdd.id, formData)
        .then((response) => {
          console.log(response);
          this.showAllProduit();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialogOfEdit = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },

    initformadd() {
      this.errorsAdd = [];
      this.previewImage = null;
      this.previewImageup = null;
      this.categorie = null;
      this.image = null;
      this.multiselectValueAdd = [];
      this.produitToAdd.fournisseur_id = [];
      this.produitToAdd.id = null;
      this.produitToAdd.nomProduit = null;
    
      this.produitToAdd.prixAchat = null;
      this.produitToAdd.prixVente = null;
      this.produitToAdd.stock = null;
      this.produitToAdd.seul = null;
      this.produitToAdd.nomCategorie = null;
      this.produitToAdd.numSerie = null;
      this.produitToAdd.descriptionProduit = null;
      this.produitToAdd.unite = "unité";
      (this.produitToAdd.etat = 1), (this.produitToAdd.imageProduit = null);
    },
    showAllProduit() {
      axios
        .get("ListeProduits")
        .then((response) => {
          this.produits = response.data.produits;
          console.log("this.produits",this.produits)
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    addPoduit() {
      const formData = new FormData();
      formData.append("nomProduit", this.produitToAdd.nomProduit);
      
      formData.append("prixAchat", this.produitToAdd.prixAchat);
      formData.append("prixVente", this.produitToAdd.prixVente);
      formData.append("stock", this.produitToAdd.stock);
      formData.append("nomCategorie", this.produitToAdd.nomCategorie);
      formData.append("seul", this.produitToAdd.seul);
      formData.append("unite", this.produitToAdd.unite);
      formData.append(
        "descriptionProduit",
        this.produitToAdd.descriptionProduit
      );
      formData.append("numSerie", this.produitToAdd.numSerie);
      formData.append("etat", this.produitToAdd.etat);
      formData.append("imageProduit", this.image);
      formData.append("categorie_id", this.categorie.id);
      formData.append(
        "fournisseur_id",
        JSON.stringify(this.produitToAdd.fournisseur_id)
      );

      axios
        .post("addProduit", formData)
        .then((response) => {
          console.log(response);
          this.showAllProduit();
          this.$swal({
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialogOfAdd = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    showaddPoduit() {
      this.initformadd();
      this.errorsAdd = [];
      axios
        .get("formAddProduit")
        .then((response) => {
          this.categories = response.data.categories;
          this.fournisseurs = response.data.fournisseur;
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
      this.dialogOfAdd = true;
    },
    checkFormAdd() {
      let checked = true;
      this.errorsAdd = [];
      this.produitToAdd.fournisseur_id = [];
      if (this.multiselectValueAdd) {
        // this.produitToAdd.fournisseur_id = this.multiselectValueAdd
        //   .map((a) => a.id)
        //   .toString();
        for (const element of this.multiselectValueAdd) {
          this.produitToAdd.fournisseur_id.push(element.id);
        }
      }

      if (this.produitToAdd.fournisseur_id.length == 0) {
        this.errorsAdd["fournisseur_id"] = "Fournisseur est obligatoire.";
        checked = false;
      }
      console.log(JSON.stringify(this.produitToAdd.fournisseur_id));

      if (
        this.produitToAdd.nomProduit == null ||
        this.produitToAdd.nomProduit == ""
      ) {
        this.errorsAdd["nomProduit"] = "Libellé est obligatoire.";
        checked = false;
      }
      if (
        this.produitToAdd.descriptionProduit == null ||
        this.produitToAdd.descriptionProduit == ""
      ) {
        this.errorsAdd["descriptionProduit"] = "Libellé est obligatoire.";
        checked = false;
      }
      if (
        this.produitToAdd.numSerie == null ||
        this.produitToAdd.numSerie == ""
      ) {
        this.errorsAdd["numSerie"] = "Numéro de série est obligatoire.";
        checked = false;
      }
      
      if (this.categorie == null) {
        this.errorsAdd["nomCategorie"] = "Catégorie est obligatoire.";
        checked = false;
      }
      if (this.produitToAdd.prixAchat == null) {
        this.errorsAdd["prixAchat"] = "Prix d'achat est obligatoire.";
        checked = false;
      }
      if (this.produitToAdd.prixVente == null) {
        this.errorsAdd["prixVente"] = "Prix de vente est obligatoire.";
        checked = false;
      }
      if (this.produitToAdd.stock == null) {
        this.errorsAdd["stock"] = "Stock est obligatoire.";
        checked = false;
      }
      if (this.produitToAdd.seul == null) {
        this.errorsAdd["seul"] = "Seuil est obligatoire.";
        checked = false;
      }
      if (checked) {
        if (this.dialogOfAdd) {
          this.addPoduit();
        } else {
          this.updateclient();
        }
      }
    },

    updateClient(id) {
      this.initformadd();
      axios
        .get("getProduitId/" + id)
        .then((response) => {
          console.log(response.data);
          this.categories = response.data.categories;
          this.fournisseurs = response.data.fournisseur;
          this.produitToAdd = response.data.produit;
          this.previewImageup = response.data.produit.imageProduit;

          this.categorie = this.categories.find(
            (x) => x.id === this.produitToAdd.categorie_id
          );
          if (response.data.produitFournisseur.length > 0) {
            response.data.produitFournisseur.forEach((element) => {
              this.multiselectValueAdd.push(
                this.fournisseurs.find((x) => x.id === element.fournisseur_id)
              );
            });
          }
          console.log("this.multiselectValueAdd", this.multiselectValueAdd);
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });

      this.dialogOfEdit = true;
    },

    removeImage() {
      this.previewImage = null;
      this.image = null;
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        this.errorimage = null;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        this.image = this.$refs.fileInput.files[0];
        // console.log(this.$refs.fileInput.files[index]);
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },

    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    formatDate(value) {
      return value.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    calculateCustomerTotal(name) {
      let total = 0;
      if (this.customer3) {
        for (let customer of this.customer3) {
          if (customer.representative.name === name) {
            total++;
          }
        }
      }

      return total;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
@import "../assets/demo/badges.scss";
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}
.is-invalid {
  border-color: #e24c4c;
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}
</style>